















  import { ServerResponse } from '@/services/response.types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action } from 'vuex-class';

  @Component({
    name: 'ProtectNow',
    components: {},
    mixins: [],
  })
  export default class ProtectNow extends Vue {
    @Action('SetNewsteller') SetNewsteller!: (params: { email: string }) => Promise<ServerResponse>;

    form = {
      email: '',
    };

    public created(): void {
      // on componenet created
    }

    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      this.SetNewsteller(this.form).then((response) => {
        this.form.email = ""
      })
    }
  }
